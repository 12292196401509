.video-background {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -5;
  }
  
  .video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 115%;
    transform: translate(-50%, -50%);
    z-index: -4;
    object-fit: fill; 
  }
  
  
  